
import './App.css';

import Example from './components/NavBar';
import EmblaCarousel from './components/Carousel'
import Votd from './components/Votd';
import { useEffect } from 'react';
import Photos from './components/Photos';





import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import About from './components/About';
import Home from './components/Home';


function App() {


  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://app.mailjet.com/pas-nc-embedded-v1.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  })

  const OPTIONS = { loop: true }
  const SLIDE_COUNT = 4
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys())


  return (
    <div className="App">
      <Example>   
      <Router>
        <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/photos" element={<Photos />} />
        </Routes>
        </Router>
      </Example>
      
<footer class="bg-white d:bg-gray-900">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div class="md:flex flex-col md:justify-between">
          <div style={{display:'flex',flexDirection:'column'}} class="flex flex-col mb-6 md:mb-0">
              <a href="https://utcfny.com/" class="flex flex-col sm:flex-row items-center">
                  <img src="https://res.cloudinary.com/lucidb/image/upload/v1691686736/utcfny/replicate-prediction-ayemc7bbxcabzaclp6xn64grbm-modified_l354b6.png" 
                  class="h-20  sm:h-40 mr-3" alt="Logo" />
                  <span class="p-4 self-center text-l sm:text-2xl font-semibold whitespace-nowrap d:text-white">
                    <br/> United Telugu Christian Fellowship</span>
              </a>
          </div>

      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto d:border-gray-700 lg:my-8" />
      <div class="sm:flex sm:items-center sm:justify-between">
          <span class="text-sm text-gray-500 sm:text-center d:text-gray-400">© 2023 <a href="https://utcfny.com/" class="hover:underline">UTCFNY</a>. All Rights Reserved.
          </span>
          <div class="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
              <a href="#" class="text-gray-500 hover:text-gray-900 d:hover:text-white">
                  <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                  <span class="sr-only">Facebook page</span>
              </a>
              <a href="#" class="text-gray-500 hover:text-gray-900 d:hover:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
</svg>
                  <span class="sr-only">Youtube page</span>
              </a>
          </div>
      </div>
    </div>
</footer>

    </div>
  );
}

export default App;
