const people = [
    {
      name: 'Bro. Devanivas Johnson Gandham',
      role: 'President',
      imageUrl:
        'https://res.cloudinary.com/lucidb/image/upload/v1691686114/utcfny/image_huviub.png',
    },
    // More people...
    {
        name: 'Bro. Joseph Gaddam',
        role: '',
        imageUrl:
          'https://res.cloudinary.com/lucidb/image/upload/v1691686297/utcfny/Joseph-web-2_vkyh1g.png',
      },
      {
        name: 'Bro. Sudhakar Samuel Pagadala',
        role: '',
        imageUrl:
          'https://res.cloudinary.com/lucidb/image/upload/v1691686451/utcfny/SSP-Web2_1_druhcx.png',
      },
  ]
  
  export default function Team() {
    return (
      <div className="bg-white py-24 sm:py-12">
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-4">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our leadership</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">

            </p>
          </div>
          <ul role="list" className="grid gap-x-20 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-3">
            {people.map((person) => (
              <li key={person.name}>
                <div className="flex items-center gap-x-12 ">
                  <img className="h-auto w-[144px] rounded-full object-fill" src={person.imageUrl} alt="" />
                  <div className="flex flex-col gap-x-6 ">
                    <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                    <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
  