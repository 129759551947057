
import react, { useState } from "react"
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'



function Photos() {

    const [isClicked, setIsClicked] = useState(true)

   function toggleClicked() {
        setIsClicked(!isClicked)
        console.log(isClicked)
    }

    let data = [
        {
          "title": "Silver Wedding Anniversary of Venigalla Couple and Gadde Couple",
          "vid": "gFcpotkLlUA",
          "date":'2017-01-03',
        },
        {
            "title": "Message by Pastor John Wesley . Telugu Christian Church-New York",
            "vid": "yoa2lesnfco",
            "date":'2017-01-03',
        },
        {
            "title": "Kids @ UTCF -NY",
            "vid": "9tt8dn2sFs8",
            "date":'2017-01-03',
        },
        {
            "title": "Kids @ UTCF -NY",
            "vid": "9tt8dn2sFs8",
            "date":'2017-01-03',
        },
        {
            "title": "Sharoon Vemu",
            "vid": "TrOnnP48KtE",
            "date":'2017-01-03',
        },
        {
            "title": "2016 United Telugu Christmas Carols. New York,USA",
            "vid": "LVJJgI_4XCs",
            "date":'2017-01-03',
        },
        {
            "title": "UTCF Silver Jubilee Celebrations in New York - USA - TV9",
            "vid": "CR4gzhSGLOM",
            "date":'2017-01-03',
        },
    ]


    let imageData = [
        {
            "title": "",
            "link": ""
        }
    ]

    return(
        <div>
      
<div class="border-b border-gray-200 dark:border-gray-700" style={{zIndex:'1', position:'relative'}}>
    <center>
    <ul class="flex justify-center -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400 ">
        <li class="mr-2">
            <button onClick={toggleClicked} 
            class={isClicked ? "inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group" :
            "inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"}>
                <svg class= { isClicked ? 
                 "w-4 h-4 mr-2  text-blue-600 group-hover:text-gray-500" : 
                 "w-4 h-4 mr-2  text-gray-400 group-hover:text-gray-500"}
                 aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                </svg>Videos
            </button>
        </li>
        <li class="mr-2">

            <button onClick={toggleClicked} href="#" 
            class= {isClicked ? "inline-flex items-center justify-center p-4 text-grey-600 border-b-2 rounded-t-lg active group" :
        "inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group"}
            aria-current="page">
                <svg class={isClicked ? "w-4 h-4 mr-2 text-grey-600":"w-4 h-4 mr-2 text-blue-600"} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
                </svg>Photos
            </button>
        </li>

    </ul>
    </center>
</div>

{isClicked ? 
    <div class="container my-12 mx-auto px-4 md:px-12">
    <div class="flex flex-wrap -mx-1 lg:-mx-4">


{data.map((i) => (
           <div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

           {/* <!-- Article --> */}
           <article class="overflow-hidden rounded-lg shadow-lg">
          


                   <LiteYouTubeEmbed  class="block h-auto w-full" id={i.vid}/>
     

               <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                   <h1 class="text-lg">
                       <p class="no-underline hover:underline text-lg font-semibold text-black" href="#">
                          {i.title}
                       </p>
                   </h1>

               </header>

               

               <footer class="flex items-center justify-between leading-none p-2 md:p-4">
                   <p class="flex items-center no-underline hover:underline text-black" href="#">
                      
                       <p class="ml-2 text-sm">
                           {i.date}
                       </p>
                   </p>
               </footer>

           </article>
           {/* <!-- END Article --> */}

       </div>
))}
    </div>
</div> : <div>
<div class="grid grid-cols-2 md:grid-cols-3 gap-4 p-20">
    <div>
        <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image.jpg" alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-1.jpg" alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-2.jpg" alt="" />
    </div>
    <div>
        <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/square/image-3.jpg" alt="" />
    </div>
    <div>
        <iframe title="de" class="h-auto max-w-full rounded-lg" src="http://www.youtube.com/embed/xDMP3i36naA" alt=""> </iframe>
    </div>

</div>
</div>}
        </div>
    )
}


export default Photos