import { XMarkIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect } from 'react'


export default function Votd() {


  const [votd, setVotd] = useState([]);

  useEffect(() => {
    fetch('https://labs.bible.org/api/?passage=votd&type=json&formatting=plain')
       .then((response) => response.json())
       .then((data) => {
          setVotd(data[0]);
 
       });
 },[]);

  return (
    <center style={{padding:'10px'}}>
    <div className="relative isolate flex items-center justify-center overflow-hidden bg-gray-50  text-sm lg:text-lg text-center h-fit" style={{width:'100%',padding:'20px', textAlign:'center'}}>
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
 
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
          <div className='p-4 bg-opacity-80'>
            
<figure className=" mx-auto text-center">


    <svg className="w-8 h-8 mx-auto mb-3 text-gray-400 d:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" >
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
</svg>

    <p className='font-bold text-xl pb-3'> Verse of the day</p>
    <blockquote>
        <p className="text-l italic font-medium text-gray-900 d:text-white">{votd['text']}</p>
    </blockquote>
    <figcaption className="flex items-center justify-center mt-6 space-x-3">
        {/* <img className="w-20 h-fit" src="https://res.cloudinary.com/lucidb/image/upload/v1689803413/utcfny/main%20carousel/bible-2110439_1920_dhbgzc.jpg" alt="bible"/> */}
        <div className="flex items-center divide-x-2 divide-gray-500 d:divide-gray-700">
            <cite className="pr-3 font-medium text-gray-900 d:text-white">{votd['bookname']}</cite>
            <cite className="pl-3 text-sm text-gray-500 d:text-gray-400">{votd['chapter']} : {votd['verse']}</cite>
           
        </div>
    </figcaption>
</figure>

          </div>
    </div>
    </center>
  )
}

// {votd!==[] ? <>Generated {votd['bookname']}</> : <></>}