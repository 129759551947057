import EmblaCarousel from './Carousel'
import Votd from './Votd';
import { useEffect } from 'react';

import { XMarkIcon } from '@heroicons/react/20/solid'

export default function Home() {

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://app.mailjet.com/pas-nc-embedded-v1.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  })

  const OPTIONS = {}
  const SLIDE_COUNT = 4
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

    return (
        <>
        
    <EmblaCarousel slides={SLIDES} options={OPTIONS} />
      

      {/* Banner start */}
      <center>
      <div className="relative isolate flex mb-6 items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-4 text-center justify-center before:flex-1 mx-3 sm:pt-1 pt-5">
      <div
        className="absolute justify-center left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        {/* <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        /> */}
      </div>
      {/* <div className="flex flex-wrap justify-center items-center gap-x-4 gap-y-2">
        <p className="sm:text-lg text-sm leading-6 text-gray-900">
          <strong className="font-semibold">
          Register for the conference here
            </strong>
        </p>
        <a
          href="https://www.letusall.com/meetings/"
          className="flex-none rounded-full bg-gray-900 px-3.5 py-1 sm:text-lg text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Register now <span aria-hidden="true">&rarr;</span>
        </a>
      </div> */}
      <div className="flex flex-1 justify-end">

      </div>
    </div>
    </center>
      {/* Banner end */}
      <Votd/>
      <div>
      <iframe title='newsletter' data-w-type="embedded" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://093r9.mjt.lu/wgt/093r9/jht/form?c=d4014cfd" width="100%" style={{height: "0"}}></iframe>

<script type="text/javascript" src="https://app.mailjet.com/pas-nc-embedded-v1.js"></script>

      </div>
      </>
    )
}

