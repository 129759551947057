import Team from "./Team"

export default function About() {
    return(
        <div>
                        <div className="xl:w-[800px] xl:px-32 pb-20 px-10">
              <p className="text-base font-semibold leading-7 text-indigo-600">UTCFNY</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About us</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              United Telugu Christian Fellowship- New York (UTCF - NY) is a Christian fellowship organization serving the Lord in the New York city.
UTCF - NY was established in the year 1992 as a nonprofit organization in the State of New York. Ever since its establishment UTCF - NY conducted fellowships and conferences edifying fellow Christian believers.
              </p>
            </div>
                <Team/>
        </div>
    )
}